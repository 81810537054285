import axios from "axios";

import { bdHostname, bdCSPort } from "@/settings.js";

function postUpdateDeviceTags(deviceObj, bdAccessToken) {
  if (deviceObj !== "" && bdAccessToken !== "") {
    console.log("postUpdateDeviceTags Requested", deviceObj, bdAccessToken);

    let dataObj = {};
    let obj = {};
    dataObj = {
      tags: deviceObj["tagsList"],
    };
    obj.data = dataObj;
    let config = {
      headers: {
        Authorization: "Bearer " + bdAccessToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    console.log("postUpdateDeviceTags obj: ", obj);
    return Promise.resolve(
      axios
        .post(
          bdHostname +
            ":" +
            bdCSPort +
            "/api/sensor/" +
            deviceObj["deviceID"] +
            "/tags",
          obj,
          config
        )
        .then(async (response) => {
          console.log("postUpdateDeviceTags response: ", response.data);
          return Promise.resolve(response.data);
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return Promise.resolve({
              success: "False",
              error: error.response.data,
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("Error", error.request, error.message);
            return Promise.resolve({
              success: "False",
              error: error.message,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            return Promise.resolve({
              success: "False",
              error: error.message,
            });
          }
        })
    );
  } else {
    console.log("A username and password must be present");
  }
}

export { postUpdateDeviceTags };
