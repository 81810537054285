import crypto from "crypto";
import settings from "@/settings.js";

function encrypt(data) {
  const ENC_KEY = settings.cryptoKeys.ENC_KEY; // set random encryption key
  const IV = settings.cryptoKeys.IV; // set random initialisation vector
  // console.log("## Inside encryptUser data", ENC_KEY, IV);
  let cipher = crypto.createCipheriv("aes-256-cbc", ENC_KEY, IV);
  let encrypted = cipher.update(JSON.stringify(data), "utf8", "base64");
  encrypted += cipher.final("base64");

  // console.log("Inside encryptUser data", encrypted);
  return encrypted;
}

function decrypt(encrypted) {
  let decipher = crypto.createDecipheriv(
    "aes-256-cbc",
    settings.cryptoKeys.ENC_KEY,
    settings.cryptoKeys.IV
  );
  let decrypted = decipher.update(encrypted, "base64", "utf8");
  // console.log("decrypt data", decrypted);
  return decrypted + decipher.final("utf8");
}

function readCookie(key, cookiesRef) {
  console.log("Read Cookie function");
  let cookieObj = cookiesRef.get(key);
  // Check login condition
  if (cookieObj === null) {
    console.log("No user info. Please login!");

    return Promise.resolve({
      success: false,
      error: "No user info. Please login!",
    });
  } else {
    try {
      return Promise.resolve({
        success: true,
        data: JSON.parse(decrypt(cookieObj)),
      });
    } catch (e) {
      console.log("Cookie expired. Please login!", e);
      return Promise.resolve({
        success: false,
        error:
          "Oops! Your session has expired. Please log in again to continue.",
      });
    }
  }
}

function writeCookie(key, value, cookiesRef) {
  // console.log("Write Cookie function");

  let encryptValue = encrypt(value);
  console.log("encryptValue", encryptValue);
  // cookiesRef.set(key, encryptValue);

  if (cookiesRef.set(key, encryptValue)) {
    // console.log("Write Access Token Cookie");
    return Promise.resolve({
      success: true,
    });
  } else {
    // console.log("write cookies false");

    return Promise.resolve({
      success: false,
      error:
        "We're having trouble setting cookies. To log in successfully, " +
        "please ensure your browser allows cookie access for the website.",
    });
  }
}

export { encrypt, decrypt, writeCookie, readCookie };
