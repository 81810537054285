var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":_vm.$t('sidebar.shortTitle'),"title":_vm.$t('sidebar.title')},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashboard'),
          icon: 'tim-icons icon-chart-pie-36',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.onlineDevices'),
          icon: 'tim-icons icon-compass-05',
          path: '/mitesOnlineDevices',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.livestream'),
          icon: 'tim-icons icon-sound-wave',
          path: '/mitesLiveStream',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.mitesApps'),
          icon: 'tim-icons icon-controller',
          path: '/appStore',
        }}})]}}])}),_c('sidebar-share',{attrs:{"background-color":_vm.sidebarBackground},on:{"update:backgroundColor":function($event){_vm.sidebarBackground=$event},"update:background-color":function($event){_vm.sidebarBackground=$event}}}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }