import DashboardLayout from "src/pages/Layout/DashboardLayoutAdmin.vue";
import AuthLayout from "src/pages/Pages/AuthLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";

// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard.vue"
  );

const LiveStream = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/MitesLiveDataStream/MitesLiveStream.vue"
  );

const EventLabeler = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/MitesEventLabeler/MitesEventLabeler.vue"
  );

const onlineDevices = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/OnlineDevices/MitesOnlineDevices.vue"
  );

const spatialMap = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/SpatialDashboard/SpatialGraph.vue"
  );

const mlDashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/MitesMLDashboard/MLDashboard.vue"
  );

const appStore = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/MitesAppStore/AppStore.vue"
  );

const occupancyApp = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/MitesAppStore/occupancyApp.vue"
  );

const noiseLevelApp = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/MitesAppStore/noiseLevelApp.vue"
  );

const activityRecognitionApp = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/MitesAppStore/activityRecognitionApp.vue"
  );

const activityRecognitionAppMLIoT = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/MitesAppStore/activityRecognitionAppMLIoT.vue"
  );

// Pages
const Login = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Login.vue");
const ResetPassword = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/ResetPassword.vue");
const User = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/UserProfile.vue");
const TimeLine = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/TimeLinePage.vue");
const Register = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/RegisterMites.vue");
const Lock = () =>
  import(/* webpackChunkName: "pages" */ "src/pages/Pages/Lock.vue");

let pagesMenu = {
  path: "/pages",
  component: DashboardLayout,
  name: "Pages",
  redirect: "/pages/user",
  children: [
    {
      path: "user",
      name: "User Page",
      components: { default: User }
    },
    {
      path: "timeline",
      name: "Timeline Page",
      components: { default: TimeLine }
    }
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login
    },
    {
      path: "/register",
      name: "Register",
      component: Register
    },
    {
      path: "/resetPassword",
      name: "ResetPassword",
      component: ResetPassword
    },
    {
      path: "/lock",
      name: "Lock",
      component: Lock
    }
  ]
};

var routesAdmin = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  pagesMenu,
  authPages,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    name: "Dashboard layout",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard }
      },
      {
        path: "mitesLiveStream",
        name: "LiveStream",
        components: { default: LiveStream }
      },
      {
        path: "mitesEventLabeler",
        name: "EventLabeler",
        components: { default: EventLabeler }
      },
      {
        path: "mitesOnlineDevices",
        name: "OnlineDevices",
        components: { default: onlineDevices }
      },
      {
        path: "spatialFloorMap",
        name: "Spatial Floor Maps",
        components: { default: spatialMap }
      },
      {
        path: "mitesMLDashboard",
        name: "ML Dashboard",
        components: { default: mlDashboard }
      },
      {
        path: "appStore",
        name: "Mites AppStore",
        components: { default: appStore }
      },
      {
        path: "occupancyApp",
        name: "Conference Room Availability App?",
        components: { default: occupancyApp }
      },
      {
        path: "noiseLevelApp",
        name: "Find Quite Place App?",
        components: { default: noiseLevelApp }
      },
      // {
      //   path: "activityRecognitionApp",
      //   name: "Find Quite Place App?",
      //   components: { default: activityRecognitionApp }
      // },
      {
        path: "activityRecognitionAppMLIoT",
        name: "Find Quite Place App?",
        components: { default: activityRecognitionAppMLIoT }
      }
    ]
  },
  { path: "*", component: NotFound }
];

export default routesAdmin;
