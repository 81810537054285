<template>
  <div
    class="bootstrap-switch bootstrap-switch-wrapper bootstrap-switch-animate"
    :class="switchClass"
  >
    <div class="bootstrap-switch-container" @click="triggerToggle()">
      <span class="bootstrap-switch-handle-on">
        <slot name="on"> {{ onText }} </slot>
      </span>
      <span class="bootstrap-switch-label"></span>
      <span class="bootstrap-switch-handle-off bootstrap-switch-default">
        <slot name="off"> {{ offText }} </slot>
      </span>
    </div>
  </div>
</template>
<script>
export default {
  name: "base-switch",
  props: {
    value: [Array, Boolean],
    onText: String,
    offText: String,
  },
  computed: {
    switchClass() {
      // console.log("switchClass", this.model);
      let base = "bootstrap-switch-";
      let state = this.model ? "on" : "off";
      let classes = base + state;
      // console.log("classes", classes);
      return classes;
    },
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    triggerToggle() {
      // console.log("triggerToggle", this.model);
      this.model = !this.model;
    },
  },
};
</script>
<style></style>
