import Vue from "vue";
import VueRouter from "vue-router";
import RouterPrefetch from "vue-router-prefetch";
import DashboardPlugin from "./plugins/dashboard-plugin";
import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import VueCookies from "vue-cookies";

// router setup
import router from "./routes/router";
import i18n from "./i18n";
import "./registerServiceWorker";

let cookieOptions = {
  expires: "1d",
  path: "/",
  domain: "",
  secure: false,
  sameSite: "Strict",
};

// plugin setup
Vue.use(DashboardPlugin);
Vue.use(VueAxios, axios);
Vue.use(VueCookies, cookieOptions);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);

/* eslint-disable no-new */
new Vue({
  el: "#app",
  render: (h) => h(App),
  router,
  i18n,
});

if (process.env.NODE_ENV === "production") console.log = function () {};
