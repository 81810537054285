import axios from "axios";

import { bdHostname, bdCSPort } from "@/settings.js";

function getUserOnlineDevices(email, bdAccessToken) {
  if (email !== "" && bdAccessToken !== "") {
    console.log("getOnlineSensorList Requested", email, bdAccessToken);

    let dataObj = {};
    let obj = {};
    dataObj.Tags = ["owner:" + email];
    obj.data = dataObj;
    let config = {
      headers: {
        Authorization: "Bearer " + bdAccessToken,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    return Promise.resolve(
      axios
        .post(bdHostname + ":" + bdCSPort + "/api/sensor/search", obj, config)
        .then(async (response) => {
          console.log("getOnlineSensorList response: ", response.data.result);
          let responseData = response.data.result;
          console.log(responseData);
          let allTagsMap = {};
          let mitesLookUpTable = {};
          responseData.forEach(
            ({ name, source_name, building, tags, source_identifier }) => {
              if (source_identifier === "MitesDataSensor") {
                allTagsMap[name] = tags;

                const getTagValue = (tagName, defaultValue = "") => {
                  const tag = tags.find((obj) => obj.name === tagName);
                  return tag ? tag.value : defaultValue;
                };

                mitesLookUpTable[source_name] = {
                  id: name,
                  name: source_name,
                  building,
                  room: getTagValue("location"),
                  floor: getTagValue("floor"),
                  placement: (() => {
                    const placement = getTagValue("placement");
                    if (placement.includes("C")) return "Ceiling";
                    if (placement.includes("W")) return "Wall";
                    return placement;
                  })(),
                  selectedSensors: getTagValue("sensorStatus"),
                  tags: tags,
                };
              }
            }
          );

          console.log("Looks here: ", mitesLookUpTable);
          return Promise.resolve(mitesLookUpTable);
        })
        .catch(function (error) {
          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
            return Promise.resolve({
              success: "False",
              error: error.response.data,
            });
          } else if (error.request) {
            // The request was made but no response was received
            // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
            // http.ClientRequest in node.js
            console.log("Error", error.request, error.message);
            return Promise.resolve({
              success: "False",
              error: error.message,
            });
          } else {
            // Something happened in setting up the request that triggered an Error
            console.log("Error", error.message);
            return Promise.resolve({
              success: "False",
              error: error.message,
            });
          }
        })
    );
  } else {
    console.log("A username and password must be present");
  }
}

export { getUserOnlineDevices };
