import { getAccessToken } from "@/javascripts/buildingdepot/OAuth";
import { getUserOnlineDevices } from "@/javascripts/buildingdepot/user/getUserOnlineDevices";
import { postUpdateDeviceTags } from "@/javascripts/buildingdepot/user/postUpdateDeviceTags";
import { getUserSurveys } from "@/javascripts/buildingdepot/user/getUserSurveys";
import { postUserFeedbackESM } from "@/javascripts/buildingdepot/user/postUserFeedbackESM";
import { readCookie, writeCookie } from "@/javascripts/buildingdepot/utils";

// bdAdminLogin.getAdminAccessToken();
// let routes;
// if (settings.role === "user") {
//   routes = routesUser;
// } else {
//   routes = routesAdmin;
// }

export default {
  oAuth: {
    // getAdminAccessTokenAPI: bdAdminLogin.getAdminAccessToken(),
    getAccessTokenAPI: getAccessToken,
    writeCookieAPI: writeCookie,
    readCookieAPI: readCookie,
  },
  bdUser: {
    getUserOnlineDevicesAPI: getUserOnlineDevices,
    postUpdateDeviceTagsAPI: postUpdateDeviceTags,
    getUserSurveysAPI: getUserSurveys,
    postUserFeedbackESMAPI: postUserFeedbackESM,
  },
};
