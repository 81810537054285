import VueRouter from 'vue-router';
import routesUser from "@/routes/routes";
import routesAdmin from "@/routes/routesAdmin";
import settings from '@/settings';

let routes;
// console.log(process.env.APP_ROLE)
if (settings.role === "user"){
    routes = routesUser;
} else {
    routes = routesAdmin;
}

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: 'active',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
});

export default router;
