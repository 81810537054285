import $ from "jquery";
import swal from "sweetalert2";

var shapeArray = [];
// Mites Specific Attributes
var mitesChannels = [
  "Accel X",
  "Accel Y",
  "Accel Z",
  "Microphone",
  "EMI",
  "Temperature",
  "Barometer",
  "Humidity",
  "Light",
  "Motion",
  "Color",
  "Magnetic",
  "WiFi RSSI",
  "Grid Eye",
];

function getKeys() {
  try {
    var firstDataElement = getData()[0];
    var keys = [];
    let k;
    for (k in firstDataElement) keys.push(k);
    return keys;
  } catch (e) {
    alert("Upload data");
  }
}

// Modals ////////////////////////////////

// Plots/ Graphs : Live //////////////////////////////////////////////

function createLivePlotCard(sensorId) {
  let channelName = "Mites: " + sensorId;
  // var livePlotTemplate = $('#livePlotDivTemplate').html();
  var template = $("#livePlotCardTemplate").html();
  $(".content-body").append(template);
  // $('.spectrumGraphDiv:last').attr('id', 'spectrumLiveGraphDiv' + 1);
  //
  // $(".live-plot-body").append(livePlotTemplate);
  // $('.spectrumLiveGraphDiv:last').attr('id', 'spectrumLiveGraphDiv' + 2);

  $(".channelHeader").eq(0).html(channelName);
  resetDataActions(); // Close, expand
}

// Plots/ Graphs : Static //////////////////////////////////////////////

var selectedSensor = "";
function setSelectedSensor() {
  selectedSensor = "Other";
  selectedSensor = "Mites"; // @ToDO: Change selected sensor here
  selectChannels();
}

// Show a modal/ list all the key names
function selectChannels() {
  // var data = getData();
  // if(data.length != 0) {
  var keys = getKeys();
  console.log(keys);
  $("#selectStaticChannelModal").modal("show"); // Show modal to select the type of the machine
  populateChannelTable();
  // if(selectedSensor == "Mites") {
  // getMitesChannels();
  // }

  // }
  // else
  //   console.log("Data not uploaded");
}

function plotGraph(colorScale) {
  console.log(selectedChannelArray);
  for (var index in selectedChannelArray) {
    let channelName = selectedChannelArray[index];
    let plotGraph = new PlotGraph();
    var data = getData();
    // Plot graph in the graph
    var timeStamp = [];
    var sensorFullData = [];
    var timeSeriesFullData = [];
    // var data = results.data;
    var sTime = data[0]["TimeStamp"];
    var eTime = data[data.length - 2]["TimeStamp"];
    // for(i in data) {
    // console.log(data);
    for (i = 0; i < data.length; i++) {
      timeStamp.push(data[i]["TimeStamp"]);
      // @ToDo: Put the configuration in the mites connector
      var dataFft = data[i];
      var sensorData = [];
      for (j in dataFft) {
        // Spectorgram
        if (j.includes("Mic-0_") && channelName === "Microphone") {
          sensorData.push(data[i][j]);
        }
        if (j.includes("Accel-0_") && channelName === "Accel X") {
          sensorData.push(data[i][j]);
        }
        if (j.includes("Accel-1_") && channelName === "Accel Y") {
          sensorData.push(data[i][j]);
        }
        if (j.includes("Accel-2_") && channelName === "Accel Z") {
          sensorData.push(data[i][j]);
        }
        if (j.includes("EMI-0_") && channelName === "EMI") {
          sensorData.push(data[i][j]);
        }
        // Time Series
        if (j.includes("Temp.-0_") && channelName === "Temperature") {
          timeSeriesFullData.push(data[i][j]);
        }
      }
      sensorFullData.push(sensorData);
    }
    plotGraph.plotSpectrogram(
      timeStamp,
      sensorFullData,
      "spectrumGraphDiv" + index,
      colorScale
    );
    plotGraph.plotStaticTimeSeries(timeStamp, timeSeriesFullData);
  }
}

// UI /////////////////////////////////////

function resetDataActions() {
  // Collapsible Card
  $('a[data-action="collapse"]').on("click", function (e) {
    e.preventDefault();
    $(this).closest(".card").children(".card-content").collapse("toggle");
    $(this)
      .closest(".card")
      .find('[data-action="collapse"] i')
      .toggleClass("ft-minus ft-plus");
  });
  // Toggle fullscreen
  $('a[data-action="expand"]').on("click", function (e) {
    e.preventDefault();
    $(this)
      .closest(".card")
      .find('[data-action="expand"] i')
      .toggleClass("ft-maximize ft-minimize");
    $(this).closest(".card").toggleClass("card-fullscreen");
  });
  // Close Card
  $('a[data-action="close"]').on("click", function () {
    $(this).closest(".card").removeClass().slideUp("fast");
  });
}

function notifyVue(verticalAlign, horizontalAlign, color, msg, type) {
  const Toast = swal.mixin({
    toast: true,
    position: verticalAlign,
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener("mouseenter", swal.stopTimer);
      toast.addEventListener("mouseleave", swal.resumeTimer);
    },
  });

  let title = "Success";
  let icon = "success";
  if (type === "error") {
    title = "Error";
    icon = "error";
  }

  Toast.fire({
    icon: icon,
    title: msg,
  });
}

export { createLivePlotCard, notifyVue };
